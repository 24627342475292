import React, { useMemo } from 'react';
import dynamic from 'next/dynamic';
import type { PageInfo } from '@marty-js/api-sdk/types';
import type { Link } from '../article-processor/types';
import { useDatalayer } from '../utils/AnalyticsContext';
import type { GlobalData, PageContentProps } from './template/types';
import type { LegacyData } from './template/item/legacy';
import type { DownloadData } from './template/item/download/types';
import type { AuthorData } from './template/item/author-item';
import type { DefaultItemData } from './template/item/default';
import type { ProductData } from './template/item/product';
import type { NewsData } from './template/item/news';
import type { DealsData } from './template/item/deals';
import type { BestPickData } from './template/item/best-pick';
import type { GuideData } from './template/item/guide';
import { ReviewData } from './template/item/review';
import { LongReadData } from './template/item/long-read';
import { TutorialData } from './template/item/tutorial';
import { LaboData } from './template/item/labo';

interface ItemData {
  mainArticle: {
    articleJson: any;
    wordCount: any;
    index?: Link[];
    firstTitleRow?: string;
  };
}

const Product = dynamic(() => import('./template/item/product'));
const AuthorItem = dynamic(() => import('./template/item/author-item'));
const DefaultItem = dynamic(() => import('./template/item/default'));
const News = dynamic(() => import('./template/item/news'));
const DownloadItem = dynamic(() => import('./template/item/download'));
const OnlineService = dynamic(() => import('./template/item/online-service'));
const Legacy = dynamic(() => import('./template/item/legacy'));
const Deals = dynamic(() => import('./template/item/deals'));
const Guide = dynamic(() => import('./template/item/guide'));
const BestPick = dynamic(() => import('./template/item/best-pick'));
const Review = dynamic(() => import('./template/item/review'));
const LongRead = dynamic(() => import('./template/item/long-read'));
const Tutorial = dynamic(() => import('./template/item/tutorial'));
const Labo = dynamic(() => import('./template/item/labo'));

const isSponsored = (pageInfo: PageInfo): boolean => {
  switch (pageInfo.layout) {
    case 'deal-nohp':
    case 'deals': {
      return true;
    }
    default: {
      return false;
    }
  }
};

export function ItemPageContent({ pageInfo, set0verrideAdUnit, data }: PageContentProps) {
  const itemData = useMemo<ItemData>(() => JSON.parse(pageInfo.data) || null, [pageInfo.data]);

  useDatalayer({
    type: 'item',
    wordCount: itemData.mainArticle?.wordCount,
    sections:
      pageInfo?.breadcrumb
        ?.slice(1)
        ?.map((item) => item?.url || pageInfo.section?.url)
        ?.join('|') ?? null,
    sectionsLabel:
      pageInfo?.breadcrumb
        ?.slice(1)
        ?.map((item) => item.title)
        ?.join('|') ?? null,
  });

  switch (pageInfo.dataLayout) {
    case 'download':
      return (
        <DownloadItem
          item={pageInfo.item}
          data={data as DownloadData & GlobalData}
          breadcrumb={pageInfo.breadcrumb}
          set0verrideAdUnit={set0verrideAdUnit}
        />
      );
    case 'online-service':
      return (
        <OnlineService
          item={pageInfo.item}
          data={data as DownloadData & GlobalData}
          breadcrumb={pageInfo.breadcrumb}
          set0verrideAdUnit={set0verrideAdUnit}
        />
      );
    case 'product':
      return <Product item={pageInfo.item} data={data as ProductData & GlobalData} breadcrumb={pageInfo.breadcrumb} />;
    case 'legacy':
      return (
        <Legacy
          item={pageInfo.item}
          data={data as LegacyData & GlobalData}
          breadcrumb={pageInfo.breadcrumb}
          layout={pageInfo.layout}
        />
      );
    case 'author':
      return (
        <AuthorItem item={pageInfo.item} data={data as AuthorData & GlobalData} breadcrumb={pageInfo.breadcrumb} />
      );
    case 'news':
      return (
        <News
          sponsored={isSponsored(pageInfo)}
          item={pageInfo.item}
          data={data as NewsData & GlobalData}
          breadcrumb={pageInfo.breadcrumb}
        />
      );
    case 'labo':
      return (
        <Labo
          sponsored={isSponsored(pageInfo)}
          item={pageInfo.item}
          data={data as LaboData & GlobalData}
          breadcrumb={pageInfo.breadcrumb}
        />
      );
    case 'long-read':
      return (
        <LongRead
          sponsored={isSponsored(pageInfo)}
          item={pageInfo.item}
          data={data as LongReadData & GlobalData}
          breadcrumb={pageInfo.breadcrumb}
        />
      );
    case 'deals':
      return (
        <Deals
          sponsored={isSponsored(pageInfo)}
          item={pageInfo.item}
          data={data as DealsData & GlobalData}
          breadcrumb={pageInfo.breadcrumb}
        />
      );
    case 'review':
      return (
        <Review
          sponsored={isSponsored(pageInfo)}
          layout={pageInfo.layout}
          item={pageInfo.item}
          data={data as ReviewData & GlobalData}
          breadcrumb={pageInfo.breadcrumb}
        />
      );
    case 'best-pick':
      return (
        <BestPick
          sponsored={isSponsored(pageInfo)}
          item={pageInfo.item}
          data={data as BestPickData & GlobalData}
          breadcrumb={pageInfo.breadcrumb}
        />
      );
    case 'guide':
      return (
        <Guide
          sponsored={isSponsored(pageInfo)}
          item={pageInfo.item}
          data={data as GuideData & GlobalData}
          breadcrumb={pageInfo.breadcrumb}
        />
      );
    case 'tutorial':
      return (
        <Tutorial
          sponsored={isSponsored(pageInfo)}
          item={pageInfo.item}
          data={data as TutorialData & GlobalData}
          breadcrumb={pageInfo.breadcrumb}
        />
      );
    case 'item_default':
    default:
      return (
        <DefaultItem
          sponsored={isSponsored(pageInfo)}
          item={pageInfo.item}
          data={data as DefaultItemData & GlobalData}
          breadcrumb={pageInfo.breadcrumb}
        />
      );
  }
}
